import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
//import './bootstrap.sketchy.min.css';
import { GoogleOAuthProvider } from '@react-oauth/google';

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;


ReactDOM.render(
  <GoogleOAuthProvider clientId={googleClientId}>
    <App />
  </GoogleOAuthProvider>
  ,
  document.getElementById('root')
);
