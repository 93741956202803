
import { request } from "./pip3d_common";
import { start2 as audio_start, getRTCPeerConnectionConf } from "./pip3d_audio";

const dbg = console.log;

export async function start(info, iceServers, stateCb, unmuteCb, timeout) {

    if (!info.audioURL) {
        dbg("audio disabled");
        return false;
    }

    return audio_start({
        signalingUrl: info.audioURL,
        iceServers: iceServers,
        stateCb: stateCb,
        unmuteCb: unmuteCb,
        connectTimeout: timeout,
    });
}

export { start2, stop2, stop } from "./pip3d_audio";
