import * as React from 'react';
import { Redirect } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import './RemoteConsole.css'

class RemoteConsole extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lines: [],
            filter: "",
            sessions: new Set()
        }
    }

    host = window.location.host;
    isSsl = window.location.protocol === "https:";
    ws = new WebSocket((this.isSsl ? 'wss://' : 'ws://') + this.host + "/logs/console");

    addLine = function (line) {
        this.addLines([line]);
    }

    addLines = function (lines) {
        let sessions = this.state.sessions;
        lines.forEach(element => {
            sessions.add(element.sender)
        });
        this.setState({
            lines: lines.concat(this.state.lines),
            sessions: sessions
        })
    }

    componentDidMount() {
        this.ws.onopen = () => {
            this.addLine({ id: "connect", date: (new Date()).toISOString(), sender: "console", msg: "connected" });
        }

        this.ws.onmessage = evt => {
            this.addLines(JSON.parse(evt.data));
        }

        this.ws.onclose = () => {
            this.addLine({ id: "disconnect", date: (new Date()).toISOString(), sender: "console", msg: "disconnected" });
        }

    }

    filterLines = function (filterText) {
        this.setState({
            lines: this.state.lines,
            filter: filterText,
            sessions: this.state.sessions
        });
    }

    render() {

        if (!this.props.user)
            return <Redirect to='/' />;

        let lines = this.state.lines.filter(line => line.sender.includes(this.state.filter)).map((line) => {
            return (
                <div key={line.id}>
                    <span className="remote-console-sender">{line.sender}</span>
                    <span className="remote-consoel-date">{(new Date(line.date)).toLocaleString()}</span>
                    <div className="remote-console-text">{line.msg}</div>
                </div>
            );
        })

        let sessions = [<Dropdown.Item key="clear_filter" onClick={() => { this.filterLines("") }}>Clear filter</Dropdown.Item>];
        this.state.sessions.forEach((session) => {
            sessions.push(<Dropdown.Item key={session} onClick={() => { this.filterLines(session) }}>{session}</Dropdown.Item>)
        })

        return (
            <Container fluid className='remote-console'>
                <Row>
                    <DropdownButton id="dropdown-basic-button" title={this.state.filter ? this.state.filter : "Filter Session"} size="sm" variant="info">
                        {sessions}
                    </DropdownButton>
                </Row>
                {lines}
            </Container>
        );
    }
}

export default RemoteConsole;